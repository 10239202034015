<template>
  <div class="flex w-full flex-col gap-5">
    <div class="flex items-center gap-2">
      <img
        v-if="step !== 'manager'"
        class="h-20 w-20 rounded-full"
        :src="getImageUrl"
        alt="Name"
      />
      <img
        v-else
        class="h-20 w-20 rounded-full"
        :src="getManagerUrl"
        alt="Name"
      />
      <div
        v-if="step !== 'manager'"
        class="flex flex-grow flex-col text-sm leading-none text-gray-500"
      >
        <h3 class="mb-1 font-semibold text-black">
          {{ rep.representative?.name + " " + rep.representative?.last_name }}
        </h3>
        <p class="mb-1 text-xs">
          {{ rep.representative?.phone_number }}
        </p>
        <UseClipboard
          v-if="rep.representative?.email"
          :source="rep.representative.email"
          v-slot="{ copy, copied }"
        >
          <div
            class="flex items-center space-x-1 cursor-pointer group"
            @click="copy()"
          >
            <span>{{ rep.representative.email }}</span>
            <CheckIcon class="w-4 h-4 text-green-700" v-if="copied" />
            <DocumentDuplicateIcon class="w-4 h-4 " v-else />
          </div>
        </UseClipboard>
      </div>
      <div
        v-else
        class="flex flex-grow flex-col text-sm leading-none text-gray-500"
      >
        <h3 class="mb-1 font-semibold text-black">
          {{ rep.manager?.name + " " + rep.manager?.last_name }}
        </h3>
        <p class="mb-1 text-xs">VP of Sales</p>
        <p class="mb-1 text-xs">{{ rep.manager?.phone_number }}</p>
        <span>{{ rep.manager?.email }}</span>
      </div>
    </div>
    <div class="flex flex-col items-center gap-3" v-if="step == 'start'">
      <button
        @click="step = 'msg'"
        class="flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-2.5 text-sm font-medium leading-none text-brand-secondary hover:text-brand-primary hover:bg-gray-50 transition-colors "
      >
        <Icon name="message" />
        <span class="block flex-grow text-left">Send Message</span>
      </button>
      <button
        @click="step = 'callback'"
        class="flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-2.5 text-sm font-medium leading-none text-brand-secondary hover:text-brand-primary hover:bg-gray-50 transition-colors "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="h-4 w-4 flex-shrink-0"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
          />
        </svg>
        <span class="block flex-grow text-left">Ask for a callback</span>
      </button>
      <button
        v-if="rep.representative?.calendly_link"
        @click="onCalendlyClick(rep.representative?.calendly_link)"
        class="flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-2.5 text-sm font-medium leading-none text-brand-secondary hover:text-brand-primary hover:bg-gray-50 transition-colors "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="h-4 w-4 flex-shrink-0"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
          />
        </svg>
        <span class="block flex-grow text-left">Schedule a meeting</span>
      </button>
      <button
        v-if="rep.representative?.calendly_link"
        @click="step = 'manager'"
        class="flex w-full items-center gap-2 rounded border border-gray-300 px-5 py-2.5 text-sm font-medium leading-none text-brand-secondary hover:text-brand-primary hover:bg-gray-50 transition-colors "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="h-4 w-4 flex-shrink-0"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
          />
        </svg>
        <span class="block flex-grow text-left"
          >Contact {{ rep.representative?.name }}'s Manager</span
        >
      </button>
    </div>
    <template v-else>
      <div
        class="flex flex-col items-end"
        v-if="['manager', 'msg'].includes(step)"
      >
        <p class="text-sm mb-2 text-brand-secondary font-light w-full">
          This message will be delivered instantly
        </p>
        <textarea
          v-model="msg"
          @input="checkCharacterLimit"
          name="msg"
          id="msg"
          rows="6"
          class="mb-3 block w-full rounded-md border-0 px-3.5 py-2 text-brand-primary shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 resize-none"
        ></textarea>
        <button
          @click="sendMsg"
          class="text-sm rounded-md inline-block px-4 py-2 leading-snug text-white bg-brand-primary hover:bg-black transition-colors font-normal"
        >
          Send Message
        </button>
      </div>
      <div v-if="step == 'callback'">
        <p class="text-sm mb-2 text-brand-secondary font-normal w-full">
          Please call me back
        </p>
        <RadioGroup
          v-model="callOn.when"
          class="mb-6 flex items-stretch justify-start gap-3"
        >
          <RadioGroupOption
            as="template"
            value="today"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">Today</RadioGroupLabel>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
            value="tomorrow"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">Tomorrow</RadioGroupLabel>
            </div>
          </RadioGroupOption>
        </RadioGroup>

        <p class="text-sm mb-2 text-brand-secondary font-normal w-full">
          What time?
        </p>
        <RadioGroup v-model="callOn.time" class="mb-6 flex items-start gap-1.5">
          <RadioGroupOption
            as="template"
            value="asap"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">ASAP</RadioGroupLabel>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
            value="morning"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">Morning</RadioGroupLabel>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
            value="afternoon"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">Afternoon</RadioGroupLabel>
            </div>
          </RadioGroupOption>
          <RadioGroupOption
            as="template"
            value="evening"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active ? 'ring-2 ring-brand-primary ring-offset-2' : '',
                checked
                  ? 'bg-brand-primary text-white hover:bg-brand-primary'
                  : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                'flex items-center justify-center rounded-md py-2 px-2.5 text-xxs font-normal uppercase sm:flex-1 cursor-pointer',
              ]"
            >
              <RadioGroupLabel as="span">Evening</RadioGroupLabel>
            </div>
          </RadioGroupOption>
        </RadioGroup>
        <div class="flex items-stretch gap-3 w-full">
          <div class="relative flex flex-grow items-stretch">
            <input
              type="tel"
              name="phone"
              id="phone"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 px-3 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand sm:text-sm sm:leading-6 focus:outline-none"
              placeholder="(123) 456-7890"
              v-model="callOn.number"
              v-maska="'(###) ###-####'"
            />
          </div>
          <button
            type="button"
            @click="callBack"
            class="text-sm rounded-md inline-block px-4 py-2 leading-snug text-white bg-brand-primary hover:bg-black transition-colors font-normal"
          >
            Call Me
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, inject, toRefs, watch } from "vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { useStore } from "vuex";
import { UseClipboard } from "@vueuse/components";
import { CheckIcon, DocumentDuplicateIcon } from "@heroicons/vue/outline";

export default {
  name: "ContactRep",
  emits: ["openCalendlyModal"],
  components: {
    UseClipboard,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    DocumentDuplicateIcon,
    CheckIcon,
  },
  setup(props, { emit }) {
    const store = useStore();
    const axios = inject("axios");
    const step = ref("start");

    const msg = ref("");

    const callOn = reactive({
      when: "today",
      time: "asap",
      number: "",
    });

    const resetForm = () => {
      callOn.when = "today";
      callOn.time = "asap";
      callOn.number = "";
      msg.value = "";
      document.querySelector("#closeBtn")?.click();
      step.value = "start";
    };

    const sendMsg = () => {
      if (!msg.value) return;

      // send message
      axios.authApi
        .post("/contactRep", {
          message: msg.value,
          rep: step.value == "manager" ? "manager" : "rep",
        })
        .then((res) => {
          store.dispatch("setToast", {
            status: true,
            msg: "Message sent successfully",
            type: "success",
          });
          console.log("res", res);
        });

      // reset and close on successful send
      resetForm();
    };

    const callBack = () => {
      if (!callOn.number) return;

      // send message
      axios.authApi
        .post("/contactRep", {
          callOn: {
            when: callOn.when,
            time: callOn.time,
            number: callOn.number,
          },
        })
        .then((res) => {
          store.dispatch("setToast", {
            status: true,
            msg: "Message sent successfully",
            type: "success",
          });
          console.log("res", res);
        });
      // reset and close on successful send
      resetForm();
    };

    const checkCharacterLimit = () => {
      const maxCharacters = 1000;
      if (msg.value.length > maxCharacters) {
        // Trim the excess characters
        msg.value = msg.value.slice(0, maxCharacters);
      }
    };

    onMounted(() => {
      step.value = "start";
      document
        .querySelector("#rep-panel .open-btn")
        ?.addEventListener("click", () => {
          step.value = "start";
        });
    });

    const rep = computed(() => store.getters.coins);

    const getImageUrl = computed(
      () => process.env.BASE_URL_IMG + "/" + rep.value.representative?.path
    );
    const getManagerUrl = computed(
      () => process.env.BASE_URL_IMG + "/" + rep.value.manager?.path
    );
    const onCalendlyClick = (url) => {
      resetForm();
      console.log(url);
      emit("openCalendlyModal");
      setTimeout(() => {
        window.Calendly.initInlineWidget({
          url,
          parentElement: document.getElementById("calendly-inline-widget"),
        });
      }, 300);
    };

    return {
      step,
      msg,
      resetForm,
      sendMsg,
      callBack,
      callOn,
      rep,
      getImageUrl,
      getManagerUrl,
      onCalendlyClick,
      checkCharacterLimit,
    };
  },
};
</script>
