<template>
  <div class="notification-item">
<!--      <div class="icon">
          <Icon :name="getIcon" class="w-full h-full"/>
      </div>-->
      <div class="info">
          <p>{{title}}</p>
          <span>{{desc}}</span>
      </div>
      <div class="time medusa-text-color">
          <span>{{time}}</span>
      </div>
  </div>
</template>

<script>
import { computed } from 'vue';
export default {
    name: 'NotificationItem',
    props: {
        time: String,
        title: String,
        type: String, //[delivered, approved, placed, requested, any other]
        desc: String
    },
    setup(props){
        // Icons are from SVG file. (Icon.vue) Manually placed by compressing svgs
        const getIcon = computed(() => {
            const iconType = props.type;
            if(['delivery','delivered'].includes(iconType)) return 'order-delivered';
            if(['approved','license-approved'].includes(iconType)) return 'license-approved';
            if(['order-placed','placed'].includes(iconType)) return 'order-placed'
            if(['delivery','delivered'].includes(iconType)) return 'delivery-approved';
            if(['requested','license-requested'].includes(iconType)) return 'order-request';
            return 'order-placed';
        })

        return{
            getIcon
        }
    }
}
</script>
<style lang="scss" scoped>

.notification-item{
    @apply w-full items-center px-5 py-2 hover:bg-gray-50;

    .icon{
        @apply w-12 h-12 flex-shrink-0 mr-2;
    }

    .info{
        @apply flex-grow leading-none;
        p{
            @apply text-base font-medium text-theme-secondary;
        }
        span{
            @apply block text-sm font-medium text-theme-dark-shade;
        }
    }

    .time{
        @apply w-full flex-shrink-0 text-sm font-medium;
    }
}
</style>