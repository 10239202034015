<template>
  <ul class="main-menu">
    <li>
      <router-link to="/" exact-active-class="active" @click="$emit('clicked')">
        <Icon name="dashboard" />
        <span>Home</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/collection/new-arrivals"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="new-arrivals" />
        <span>New Arrivals</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/trending"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="trend" />
        <span>Trending</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/coming-soon"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="coming-soon" />
        <span>Coming Soon</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/favorites"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="watchlist" />
        <span>Favorites</span>
      </router-link>
    </li>
    <li>
      <router-link
        to="/clearance"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="clearance" />
        <span>Clearance</span>
      </router-link>
    </li>
    <li class="border-t border-brand-ash border-opacity-30 mt-1 pb-1"></li>
    <li>
      <router-link
        to="/categories"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="all-categories" />
        <span>All Categories</span>
      </router-link>
    </li>

    <li>
      <router-link
        to="/brands"
        exact-active-class="active"
        @click="$emit('clicked')"
      >
        <Icon name="all-brands" />
        <span>All Brands</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import Product from "@/modules/Product";
export default {
  name: "MainMenu",
  setup() {
    const { isLoggedIn } = Product();
    return {
      isLoggedIn,
    };
  },
};
</script>
<style lang="scss" scoped>
.main-menu {
  @apply w-full list-none;
  li {
    @apply w-full;
    a {
      @apply w-full flex items-center space-x-3 text-sm font-medium text-brand-gray py-3 px-6 border-l-4 border-transparent hover:border-theme-primary bg-theme-primary bg-opacity-0 hover:bg-opacity-5 leading-none;
      &.active {
        @apply bg-theme-primary bg-opacity-5 border-theme-primary text-theme-dark;
      }
    }
  }
}
</style>
