<template>
    <div class="icon-with-count">
        <Icon :name="icon"/>
        <!-- <span>{{getItemsCount(cart)}}</span> -->
        <span>{{items_count}}</span>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "CartCount",
  props:{
      icon: {
          type: String,
          default: 'package-filled'
      },
      items:{
          type: [Number , String],
          default: 0
      }
  },
  computed:{
    //   ...mapGetters(['cart'])
      ...mapGetters(['items_count'])
  },
  methods:{
        getItemsCount(data) {
      let count = 0;
     
      if (data.itemsByCat) {
        data.itemsByCat.forEach((element) => {
          element.items.forEach((el) => {
            count +=parseInt(el.qty);
          });
        });
      }
      return count;
    },
  }
};
</script>

<style lang="scss" scoped>
.icon-with-count {
    @apply flex items-start relative;
    svg{
        @apply h-6 w-6 text-theme-body flex-shrink-0;
    }
    span{
        @apply relative -left-2 inline-block text-white bg-theme-secondary rounded-full leading-none;
        font-size: 10px;
        padding: 3px 5px;
        top:-5px;
    }
}
</style>
