<template>
  <div class="tour-modal">
    <div class="tour-header">
      <h3>Welcome to Medusa</h3>
      <!-- <button class="cls-btn"><Icon name="close" /></button> -->
    </div>
    <div class="tour-content">
      <Carousel class="tour-carousel" v-model="currentSlide">
        <Slide :key="1" class="tour-carousel-item">
          <div class="content-inner">
            <h4>Shipping Address</h4>
            <div class="account-switcher">
              <AccountSwitch :hideTitle="true" />
            </div>
            <p>
              Select your store shipping address in order to display products or
              some text here
            </p>
            <ul class="info-for-switch list-disc pl-3">
              <li><p>Point one explaining why</p></li>
              <li><p>Point two explaining why</p></li>
              <li><p>Point three explaining why</p></li>
            </ul>
          </div>
        </Slide>
        <Slide :key="2" class="tour-carousel-item">
          <div class="content-inner">
            <h4>Info Slide 2</h4>
          </div>
        </Slide>
        <Slide :key="3" class="tour-carousel-item">
          <div class="content-inner">
            <h4>Video Slide</h4>
            <div class="embed-container" v-if="currentSlide == 2">
              <iframe
                class="w-full max-w-full"
                src="https://player.vimeo.com/video/253989945?h=c6db007fe5&color=ef0800&title=0&byline=0&portrait=0"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div class="tour-footer">
      <button @click="currentSlide--" class="secondary" v-if="currentSlide > 0">
        Back
      </button>
      <button @click="currentSlide++" v-if="currentSlide < 2">Next</button>
      <button @click="$emit('close')" v-else>Finish</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import AccountSwitch from "../views/layouts/modules/AccountSwitch.vue";

export default {
  name: "WelcomeTour",
  emits: ["close"],
  components: {
    Carousel,
    Slide,
    Pagination,
    AccountSwitch,
  },
  setup(props, { emit }) {
    const currentSlide = ref(0);

    return {
      currentSlide,
    };
  },
};
</script>
<style lang="scss" scoped>
.tour-modal {
  @apply w-full bg-white rounded py-6 px-10 overflow-hidden relative;
  min-height: 300px;

  .cls-btn {
    @apply w-5 h-5 inline-flex items-center justify-center outline-none text-theme-body hover:text-theme-secondary absolute right-4 top-4;
    svg {
      @apply w-4 h-4;
    }
  }

  .tour-header {
    @apply w-full flex items-center justify-between;

    h3 {
      @apply text-lg font-semibold text-theme-secondary;
    }
  }

  .tour-carousel {
    @apply w-full pt-5 outline-none focus:outline-none;

    .tour-carousel-item {
      @apply w-full flex items-start;

      .content-inner {
        @apply w-full flex flex-col items-start space-y-4 text-left pb-5;
        min-height: 220px;

        p {
          @apply text-sm font-light text-theme-body max-w-md mt-1;
        }
      }
    }

    .account-switcher {
      @apply inline-block w-full max-w-xs border border-theme-border border-opacity-50 rounded;
    }
  }

  .tour-footer {
    @apply flex items-center space-x-3 pt-4 justify-end;

    button {
      @apply inline-block bg-theme-secondary text-white rounded leading-none text-xs font-light px-4 py-2 w-24;

      &.secondary {
        @apply bg-theme-bg-shade bg-opacity-50 text-theme-dark;
      }
    }
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
.tour-carousel {
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  .carousel__pagination-button::after {
    @apply rounded-full;
  }

  .store-switcher .item-opts {
    @apply max-h-28;
  }
}
</style>
