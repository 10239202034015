<template>
  <div class="sidebar">
    <div class="sidebar-logo">
      <div class="flex items-center">
        <button
          type="button"
          class="border-r border-gray-200 pr-2 mr-2 text-gray-500 focus:outline-none focus:ring-0"
          @click="$emit('close')"
        >
          <XIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <router-link to="/home">
          <Icon class="h-5 w-auto" name="logo" />
        </router-link>
      </div>
      <div class="profile-action" v-if="isLoggedIn">
        <button
          class="profile-btn"
          @click="$router.push('/account'), $emit('close')"
        >
          <span class="initials">{{ userInitials }}</span>
        </button>
        <button class="logout-btn" @click="logout">
          <Icon name="logout" />
        </button>
      </div>
    </div>
    <div class="w-full px-5 py-2">
      <router-link
        to="/lightning-order"
        class="lightning-btn lg:-mt-6"
        @click="$emit('close')"
      >
        <Icon name="lightning" />
        <span class="cursor-pointer">Lightning Order</span>
      </router-link>
    </div>
    <ContactRep @openCalendlyModal="openCalendlyModal" v-if="isLoggedIn" />

    <AccountSwitch
      v-if="isLoggedIn"
      :isLoggedIn="isLoggedIn"
      :isMobile="isMobile"
      @onSelect="$emit('close'), (showAddressPopup = false)"
      @onNoActiveAddress="showAddressPopup = true"
    />
    <MainMenu @clicked="$emit('close')" />
    <PriceFilter />
    <Filters />

    <ModalFull
      :is-visible="showAddressPopup"
      :reset-container="true"
      container-class="w-full max-w-md "
      :isOverApp="true"
      :isBlurOverlay="true"
    >
      <div class="w-full bg-white rounded-lg py-8 px-10 relative">
        <div class="w-full text-left max-w-md">
          <p class="font-light text-md text-brand-secondary mb-4">
            Please select the shipping address for this order
          </p>
          <div class="border border-gray-200 rounded-md mb-6">
            <AccountSwitch
              :isLoggedIn="isLoggedIn"
              :ignoreDefault="true"
              :hideTitle="true"
              @onSelect="isAddressSelected = true"
            />
          </div>
          <div class="flex w-full justify-end">
            <button
              class="inline-block bg-theme-secondary text-white rounded leading-none text-xs font-light px-4 py-2 w-24"
              :class="isAddressSelected ? '' : 'opacity-70 cursor-default'"
              :disabled="!isAddressSelected"
              @click="showAddressPopup = false"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </ModalFull>

    <!-- Modal for calendly iframe -->
    <ModalFull
      :is-visible="showCalendly"
      :reset-container="true"
      container-class="w-full max-w-5xl"
      :isOverApp="true"
      :isBlurOverlay="true"
    >
      <div class="w-full rounded-lg bg-white shadow-box">
        <div class="flex justify-between px-5 py-3 w-full">
          <div class="flex items-start justify-start space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="h-4 w-4 flex-shrink-0"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
            <div class="text-left text-base">
              <h3 class="text-lg text-theme-secondary font-semibold">
                Schedule a meeting
              </h3>
            </div>
          </div>
          <div>
            <div
              class="close cursor-pointer hover:bg-sky-700"
              @click="showCalendly = false"
            >
              <span>X</span>
            </div>
          </div>
        </div>
        <div
          class="calendly-inline-widget"
          id="calendly-inline-widget"
          style="position: relative;min-width:320px;height:90vh;"
          data-auto-load="false"
        ></div>
      </div>
    </ModalFull>
  </div>
</template>

<script>
import MainMenu from "./MainMenu.vue";
import AccountSwitch from "./AccountSwitch.vue";
import Filters from "./Filters.vue";
import PriceFilter from "./PriceFilter.vue";
import Auth from "@/modules/Auth";
import Brands from "@/modules/Brands";
import { XIcon } from "@heroicons/vue/outline";
import { ref, computed } from "vue";
import ContactRep from "./ContactRep.vue";

export default {
  name: "Sidebar",
  components: {
    MainMenu,
    AccountSwitch,
    Filters,
    PriceFilter,
    XIcon,
    ContactRep,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { loggedOut } = Auth();
    const { isLoggedIn } = Brands();

    const showAddressPopup = ref(false);
    const isAddressSelected = ref(false);
    const showCalendly = ref(false);
    const calendlyUrl = ref("");

    const userName = localStorage.getItem("username") || "";
    const userInitials = computed(() => {
      return userName
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    });
    const logout = () => {
      loggedOut();
    };

    const openCalendlyModal = (url) => {
      calendlyUrl.value = url;
      showCalendly.value = true;
    };

    return {
      isLoggedIn,
      userInitials,
      logout,
      showAddressPopup,
      isAddressSelected,
      openCalendlyModal,
      showCalendly,
      calendlyUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  @apply relative hidden md:flex flex-col space-y-3.5 bg-white items-start pt-28 lg:pt-20 pb-28 w-full md:w-64 lg:w-72 z-40 flex-shrink-0 overflow-x-hidden;

  .sidebar-logo {
    @apply hidden w-full justify-between px-6 py-2 items-center;
  }
  .profile-action {
    @apply flex space-x-3 items-center;
  }
  .profile-btn {
    @apply bg-white flex items-center text-sm rounded-full focus:outline-none flex-shrink-0;
    .initials {
      @apply flex items-center justify-center rounded-full h-6 w-6   bg-brand-indigo text-brand-indigodark font-semibold leading-none;
      font-size: 10px;
    }
    p {
      @apply text-sm text-theme-light px-2 flex-shrink-0;
    }
    svg {
      @apply h-5 w-5 text-theme-body flex-shrink-0;
    }
  }
  .logout-btn {
    @apply flex items-center justify-center rounded-full h-6 w-6   bg-brand-indigo text-brand-indigodark font-semibold leading-none;

    svg {
      @apply w-4 h-4;
    }
  }
  &.on-mobile {
    @apply flex pt-4 max-w-full sm:max-w-xs overflow-y-auto;
    @include CssScrollbar();
    max-height: 100vh;
    z-index: 9999;

    .sidebar-logo {
      @apply flex;
    }
  }

  .lightning-btn {
    @apply flex items-center justify-center border border-brand-ash border-opacity-80 rounded px-4 py-2.5 leading-snug text-brand-gray hover:text-brand-secondary text-sm font-medium;
    svg {
      @apply w-5 h-4 text-brand-secondary mr-2;
    }
    .intercom-namespace {
      > div[role="button"] {
        padding: 4px 8px;
        font-size: 10px;
        background-color: #7180b9;
        line-height: 1;
        height: auto;
      }
    }
  }
}
</style>

<style lang="scss">
.lightning-btn {
  .intercom-namespace {
    > div[role="button"] {
      padding: 4px 8px;
      font-size: 10px;
      background-color: #7180b9;
      line-height: 1;
      height: auto;
      font-weight: 600;
    }
  }
}
</style>
