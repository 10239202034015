<template>
  <Popover v-slot="{ open }" as="template">
    <div
      class="rep-panel-block"
      :class="{ active: open || showRepButton }"
      id="rep-panel"
    >
      <PopoverButton
        class=" flex justify-start items-center space-x-3 px-3 py-2 rounded-lg md:rounded-t-lg border w-full border-indigo-100 bg-indigo-50 shadow-box focus:outline-none "
        id="contactRepTrigger"
        @click="step = 'start'"
      >
        <img
          class="h-6 w-6 rounded-full border border-white"
          :src="getImageUrl"
          alt="Name"
        />
        <div class="flex flex-col items-start">
          <span class="text-brand-primary text-sm font-medium truncate"
            >Contact your Rep</span
          >
        </div>
      </PopoverButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="-translate-x-1 opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="-translate-x-1 opacity-0"
      >
        <div class="absolute inset-0 overflow-hidden" v-if="open">
          <PopoverPanel class="rep-panel-options">
            <PopoverButton
              class="absolute right-3 top-3 text-gray-400 transition-colors hover:text-black"
              id="closeBtn"
            >
              <Icon name="close" class="w-4 h-4" />
            </PopoverButton>

            <ContactRepForm @openCalendlyModal="$emit('openCalendlyModal')" />
          </PopoverPanel>
        </div>
      </transition>
    </div>
  </Popover>
</template>

<script>
import { ref, toRefs, computed, watch } from "vue";
import { useScroll } from "@vueuse/core";
import ContactRepForm from "./ContactRepForm.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { useStore } from "vuex";

export default {
  name: "ContactRep",
  emits: ["openCalendlyModal"],
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ContactRepForm,
  },
  setup(props, { emit }) {
    const showRepButton = ref(false);
    const store = useStore();

    const rep = computed(() => store.getters.coins);

    const getImageUrl = computed(
      () => process.env.BASE_URL_IMG + "/" + rep.value.representative?.path
    );

    const { y: bottomSpacing, isScrolling, directions } = useScroll(window);
    const { top: toTop, bottom: toBottom } = toRefs(directions);
    const timeInterval = ref(null);

    watch(isScrolling, (val) => {
      const bottomGap = Math.max(
        document.body.offsetHeight - (bottomSpacing.value + window.innerHeight),
        0
      );
      if (bottomGap < 50) {
        showRepButton.value = false;
        if (timeInterval.value) clearInterval(timeInterval.value);
        return;
      }
      if (val) {
        showRepButton.value = true;
        if (timeInterval.value) clearInterval(timeInterval.value);
      } else {
        timeInterval.value = setTimeout(() => {
          showRepButton.value = false;
        }, 3000);
      }
    });

    return {
      showRepButton,
      bottomSpacing,
      isScrolling,
      toBottom,
      toTop,
      getImageUrl,
    };
  },
};
</script>

<style lang="scss">
.rep-panel-block {
  @apply z-40 w-full px-5 md:fixed md:left-0 md:-bottom-24 md:w-64 lg:w-72 transition-all duration-500;

  &.active {
    @apply md:-bottom-0.5;
  }
}

.rep-panel-options {
  @apply fixed left-1 right-1 md:right-auto md:left-64 lg:left-72 z-10 bottom-3 md:bottom-0 transform h-96 origin-bottom-left md:max-w-md rounded bg-white px-8 py-8 shadow-xl border border-gray-100;
}
</style>
