<template>
  <div v-click-outside="onClickOutside">
    <Combobox as="div" class="search-bar" v-model="selectedItem">
      <ais-instant-search
        :search-client="searchClient"
        index-name="products_index"
      >
        <ais-search-box>
          <template
            v-slot="{ refine }"
            class="relative w-full"
            autocomplete="off"
          >
            <ComboboxInput
              @input="refineSearch(refine, $event.currentTarget.value)"
              @click="
                refine($event.currentTarget.value),
                  onInputFocus($event.currentTarget.value)
              "
              v-on:keyup.enter="onEnter"
              id="search"
              name="search-prod"
              class="search-input"
              placeholder="Start typing the name of a product or brand"
              type="search"
              autocomplete="off"
              ref="search-innput"
            />
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </template>
        </ais-search-box>
        <ComboboxOptions
          class="search-panel"
          :class="{ inactive: !showSearch }"
          static
        >
          <ais-state-results class="w-full">
            <template v-slot="{ state: { query }, results: { hits } }">
              <ais-index index-name="products_index" class="w-full">
                <ais-hits
                  class="w-full"
                  v-if="query.length > 0 && hits.length > 0"
                >
                  <template v-slot="{ items }">
                    <div class="brands-grid">
                      <!-- v-for="item in items.slice(0, 5)" -->
                      <template
                        v-for="item in getItems(items)"
                        :key="item.objectId"
                      >
                        <router-link
                          :to="slugUrl('brand/' + item.brand_slug)"
                          @click="clearQuery"
                          class="item-link"
                        >
                          <!-- v-if="item.status === 'active's && item.active === 1" -->
                          <div class="grid-item" :value="item">
                            <img
                              :src="
                                item.brand_image !== 'no-image'
                                  ? imgSrc(item.brand_image)
                                  : '/images/no-image.png'
                              "
                              :alt="item.brand"
                            />
                          </div>
                          <span>{{ item.brand }}</span>
                        </router-link>
                      </template>
                    </div>
                  </template>
                </ais-hits>
              </ais-index>
            </template>
          </ais-state-results>

          <div class="search-content">
            <ais-state-results class="search-results">
              <template
                v-slot:="{
                  results: { hitsPerPage, hits, nbHits },
                  state: { query },
                }"
              >
                <ais-configure />
                <ais-index
                  class="w-full"
                  v-if="query.length > 0"
                  index-name="products_index"
                >
                  <ais-hits class="w-full" v-if="hits.length > 0">
                    <template v-slot="{ items }">
                      <h3>PRODUCTS</h3>
                      <div class="items-scroller">
                        <ComboboxOption
                          as="template"
                          v-for="(item, index) in items.filter(
                            (i) =>
                              i.status === 'active' &&
                              i.active === 1 &&
                              (price_list == '33' || i.tags != 'GGV ONLY') &&
                              (isLoggedIn || !i.login_required)
                          )"
                          :key="item.objectId"
                          :value="item"
                          v-slot="{ active }"
                        >
                          <router-link
                            :to="item.is_bundle ? '/bundles/' + item.slug :'/products/' + item.slug"
                            @click="
                              clearQuery(
                                query,
                                index + 1,
                                item,
                                items.filter(
                                  (i) => i.status === 'active' && i.active === 1
                                ),
                                true
                              )
                            "
                          >
                            <div
                              class="result-item"
                              :class="{ active: active }"
                            >
                              <img
                                :src="
                                  item.image != null
                                    ? item.image
                                    : '/images/no-image.png'
                                "
                                :alt="item.name"
                              />
                              <div class="w-full">
                                <p>{{ item.name }}</p>
                                <span
                                  >{{ item.brand }} -
                                  {{ item.product_type }}</span
                                >
                              </div>
                            </div>
                          </router-link>
                        </ComboboxOption>
                      </div>
                    </template>
                  </ais-hits>
                  <div v-else class="no-results">
                    No results have been found.
                  </div>
                </ais-index>
              </template>
            </ais-state-results>
            <ais-state-results class=" search-suggestions">
              <template v-slot:="{ results: {}, state: { query } }">
                <ais-index index-name="products_index">
                  <ais-hits v-if="query.length > 0">
                    <template v-slot="{ items }">
                      <h3 class="px-4 ">Categories</h3>
                      <div class="items-scroller searches-scroller">
                        <router-link
                          :to="slugUrl('category/' + item.categories_slug[0])"
                          @click="clearQuery"
                          v-for="item in getCategories(items)"
                          :key="item.objectId"
                          class="suggest-item category-item px-4 py-2"
                        >
                          <img
                            :src="
                              item.category_image != null
                                ? item.category_image
                                : '/images/no-image.png'
                            "
                            :alt="item.name"
                          />
                          <p>{{ item.product_type }}</p>
                        </router-link>
                      </div>
                    </template>
                  </ais-hits>
                </ais-index>
                <ais-index index-name="products_index_query_suggestions">
                  <ais-hits v-if="query.length > 0">
                    <template v-slot="{ items }">
                      <h3 class="px-4 ">POPULAR SEARCHES</h3>
                      <div
                        class="space-y-4  items-scroller searches-scroller px-4"
                      >
                        <div
                          v-for="item in popularSearches(items)"
                          :key="item.objectId"
                          class="suggest-item"
                        >
                          <SearchIcon aria-hidden="true" />
                          <p><ais-highlight :hit="item" attribute="query" /></p>
                        </div>
                      </div>
                    </template>
                  </ais-hits>
                </ais-index>
              </template>
            </ais-state-results>
          </div>
          <!-- <ais-state-results>
          <template v-slot:="{ results: { nbHits, hits }, state: { query } }">
            <ais-index index-name="products_index">
              <div class="view-all" v-if="query.length > 0 ">
                <router-link :to="'/search?q=' + query" @click="clearQuery(query)">
                  <span>VIEW ALL PRODUCTS</span>
                </router-link>
              </div>
            </ais-index>
          </template>
        </ais-state-results> -->
          <ais-state-results>
            <template v-slot:="{ results: {}, state: { query } }">
              <ais-index index-name="products_index">
                <ais-hits v-if="query.length > 0">
                  <template v-slot="{ items }">
                    <div class="view-all" v-if="query.length > 0">
                      <router-link
                        :to="'/search?q=' + query"
                        @click="
                          clearQuery(
                            query,
                            1,
                            items.map((r) => r.name),
                            items
                          )
                        "
                      >
                        <span>VIEW ALL PRODUCTS</span>
                      </router-link>
                    </div>
                  </template>
                </ais-hits>
              </ais-index>
            </template>
          </ais-state-results>
        </ComboboxOptions>
      </ais-instant-search>
    </Combobox>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";
import Segments from "@/modules/Segments";
import vClickOutside from "click-outside-vue3";
import debounce from "lodash/debounce";
const { ProductSearched, ProductSearchedNonLoggedIn } = Segments();
import Categories from "@/modules/Categories";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import algoliasearch from "algoliasearch/lite";

export default {
  name: "SearchPanel",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    SearchIcon,
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
  },
  props: {
    price_list: {
      type: String,
    },
  },
  data() {
    return {
      searchClient: algoliasearch(
        process.env.ALGOLIA_APP_ID,
        process.env.ALGOLIA_API_KEY
      ),
      selectedItem: null,
      showSearch: false,
      searchfield: "",
      popularSearch: "",
      isLoggedIn: localStorage.getItem("LoggedUser") ? true : false,
    };
  },
  methods: {
    onEnter() {
      const hasActiveElem = document.querySelectorAll(
        ".items-scroller .active"
      );
      if (this.searchfield && !hasActiveElem.length) {
        this.$router.push("/search?q=" + this.searchfield);
        this.searchfield = "";
        this.showSearch = false;
      }
    },
    getItems(items) {
      items = items.filter(
        (i) =>
          i.status === "active" &&
          i.active === 1 &&
          (this.price_list == "33" || i.tags != "GGV ONLY") &&
          (this.isLoggedIn || !i.login_required)
      );
      return items.filter(
        (v, i, s) => i === s.findIndex((t) => t.brand === v.brand)
      );
    },
    getCategories(items) {
      items = items.filter(
        (i) =>
          i.status === "active" &&
          i.active === 1 &&
          (this.price_list == "33" || i.tags != "GGV ONLY") &&
          (this.isLoggedIn || !i.login_required)
      );
      return items
        .filter(
          (v, i, s) =>
            i === s.findIndex((t) => t.product_type === v.product_type)
        )
        .slice(0, 3);
    },
    onInputFocus(val) {
      this.searchfield = val;
      if (val) {
        this.showSearch = true;
      }
    },
    hideSearch(val, item, qry) {
      const { isLoggedIn } = Categories();
      item.query = qry;
      console.log("search");
      if (isLoggedIn) {
        ProductSearched(item);
      } else {
        ProductSearchedNonLoggedIn(item);
      }
      this.$emit("hide-search", val);
    },
    imgSrc(name = "") {
      return process.env.BASE_URL_IMG + "/" + name;
    },
    slugUrl(slug) {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    },
    clearQuery(qry, i, item, items, isProduct = false) {
      const { isLoggedIn } = Categories();

      if(isProduct){
        this.$store.dispatch("setList", {
          list_id: qry,
          list_type: 'search',
          position: i,
        });
      }

      if (i) {
        this.$store.dispatch("setProPosition", i);
        if (isLoggedIn) {
          ProductSearched(qry, i, item, items, this.popularSearch);
        } else {
          ProductSearchedNonLoggedIn(qry, i, item, items, this.popularSearch);
        }
      }
      document.getElementById("search").value = "";
      this.showSearch = false;
    },
    getSearch(val, fun) {
      if (val) {
        fun(val);
        this.searchfield = val;
      }
    },
    onClickOutside() {
      this.showSearch = false;
    },
    refineSearch: debounce(function(refine, value) {
      this.searchfield = value;
      this.showSearch = !!value;
      refine(value);
    }, 400),
    popularSearches(items) {
      const limitedItems = (items || []).slice(0, 4);
      this.popularSearch = limitedItems;
      return limitedItems;
    },
  },
  watch: {
    selectedItem(item) {
      this.$router.push("/products/" + item.slug);
      this.showSearch = false;
    },
    searchfield(val) {
      this.showSearch = !!val;
    },
  },
};
</script>

<style lang="scss">
.m-search {
  .search-bar {
    @apply max-w-full;
  }
}
.search-bar {
  @apply max-w-xl w-full relative;

  .search-input {
    @apply block text-sm font-light w-full pl-5 pr-10 truncate py-2 border border-none rounded leading-5 bg-brand-ash bg-opacity-20 placeholder-gray-400 focus:outline-none sm:text-sm focus:ring-2 focus:ring-brand-indigodark appearance-none;
  }
  ::-webkit-input-placeholder {
    font-family: inherit;
  }
  ::-moz-placeholder {
    font-family: inherit;
  }
  :-ms-input-placeholder {
    font-family: inherit;
  }
  ::placeholder {
    font-family: inherit;
  }
}
.search-panel {
  @apply w-full bg-white rounded-md overflow-hidden mt-4 shadow-lg absolute top-full z-50;
  @media (min-width: 768px) {
    min-width: 550px;
  }
  &.inactive {
    @apply hidden;
  }
  .brands-grid {
    @apply bg-brand-bg p-4 w-full flex gap-3 overflow-x-auto;
    @include CssScrollbar(5px, 0.4, 5px);
    .item-link {
      @apply flex flex-col items-center justify-start;
      .grid-item {
        @apply w-16 h-16 md:w-20 md:h-20 bg-white flex items-center justify-center rounded-full border border-gray-200 p-0.5;
        img {
          @apply w-auto h-auto max-h-12;
        }
      }
      span {
        @apply text-xxs md:text-xs text-brand-body leading-none mt-1.5 text-center;
      }
    }
  }
  .search-content {
    @apply flex w-full md:divide-x md:divide-gray-200 md:divide-opacity-70;
    .items-scroller {
      @apply w-full overflow-y-auto;
      max-height: 40vh;
      @media (min-width: 768px) {
        max-height: 50vh;
      }
      @include CssScrollbar();
    }
    h3 {
      @apply text-xs uppercase text-brand-body text-opacity-75 tracking-wide mb-4;
    }
    .search-results {
      @apply w-full md:w-3/5;
      h3 {
        @apply px-4 py-3 m-0;
      }
      .result-item {
        @apply flex items-center space-x-3 flex-shrink-0 px-4 py-3;
        &:hover,
        &.active {
          background: rgba(164, 209, 250, 0.08);
        }
        img {
          @apply w-10 h-10 object-contain object-center;
        }
        p {
          @apply text-sm leading-none text-theme-dark font-medium;
        }
        span {
          @apply text-xxs leading-none text-theme-dark-shade font-normal;
        }
      }
      .no-results {
        @apply text-xs uppercase text-brand-body text-opacity-75 tracking-wide mt-3 px-4;
      }
    }
    .search-suggestions {
      @apply hidden md:block py-0 w-2/5;

      h3:last-of-type {
        margin-top: 0px;
        border-top: 1px solid #f4f4f4;
        padding-top: 10px;
      }
      .items-scroller {
        max-height: 22vh;
      }
      .suggest-item {
        @apply flex items-center space-x-2;

        &.category-item {
          @apply cursor-pointer;
          &:hover,
          &.active {
            background: rgba(164, 209, 250, 0.08);
          }
          img {
            @apply w-10 h-10 object-contain object-center;
          }
          p {
            @apply text-sm leading-none text-theme-dark font-medium;
          }
        }
      }
      svg {
        @apply h-4 w-4 text-brand-body text-opacity-75;
      }
      p {
        @apply text-brand-body text-opacity-75 text-sm;
      }
    }
  }
  .view-all {
    @apply w-full p-3.5 border-t border-gray-200 border-opacity-70;
    a {
      @apply block uppercase text-brand-body hover:text-brand-secondary text-sm w-full text-center;
    }
  }
}
</style>
<style lang="scss">
.search-suggestions {
  .ais-Highlight-highlighted {
    @apply text-theme-secondary bg-transparent;
  }
}
.ais-InstantSearch {
  @apply w-full;
}
</style>
