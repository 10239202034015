<template>
  <div
    class="flex items-stretch bg-body min-h-screen "
    :class="
      ['set-password'].includes(currentRouteName) ? 'bg-white' : 'bg-theme-bg'
    "
  >
    <Navbar :search="false" />
    <div class="flex items-stretch w-full min-h-screen">
      <div class="contents">
        <div class="inner-wrapper">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import Navbar from "./modules/Navbar.vue";
import { useRoute } from "vue-router";

export default {
  name: "NavbarLayout",
  components: {
    Navbar,
  },
  setup() {
    const route = useRoute();
    const currentRouteName = computed(() =>
      route.name.replace(
        /[A-Z]/g,
        (match, offset) => (offset > 0 ? "-" : "") + match.toLowerCase()
      )
    );

    return {
      currentRouteName,
    };
  },
};
</script>

<style lang="scss" scoped>
.contents {
  @apply block h-full flex-grow pt-12 mx-auto;
  @include maxContainer();

  .inner-wrapper {
    @apply px-8 py-12 h-full;
  }
}
</style>
